<template>
  <div class="modal">
    <div class="modal__background" @click="closeModal"></div>
    <div class="modal__close" @click="closeModal">
      &times;
    </div>
    <div class="modal__view">
      <div class="modal__view-content">
        <component
          :is="component"
          v-if="component"
          :data="data"
          @submit="$emit('send', $event)"
          @close="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GalleryDetail from "./GalleryDetail.vue";
export default {
  name: "Modal",
  components: { GalleryDetail },
  props: {
    component: { type: String, default: null },
    copy: { type: String, default: "" },
    noClose: { type: Boolean, default: false },
    data: { type: [Object, Array], default: () => ({}) }
  },
  methods: {
    closeModal() {
      if (this.noClose) {
        return;
      }
      this.$emit("close");
    }
  }
};
</script>
