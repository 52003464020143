<template>
  <div
    v-if="paralax"
    class="lazy-img__paralax"
    :style="{ backgroundImage: 'url(' + mainSource + ')' }"
  />
  <img v-else :src="mainSource" />
</template>

<script>
export default {
  name: "LazyImg",
  props: {
    lazySrc: { type: String, default: "" },
    fullSrc: { type: String, required: true },
    paralax: { type: Boolean, default: false }
  },
  data() {
    return {
      mainSource: null
    };
  },
  created() {
    const ctx = this;
    this.mainSource = this.lazySrc;
    const img = new Image();
    img.onload = function() {
      ctx.mainSource = this.src;
    };
    img.src = this.fullSrc;
  }
};
</script>

<style lang="scss" scoped>
.lazy-img {
  &__paralax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: {
      size: cover;
      position: 50% 50%;
    }
  }
}
</style>
