<template>
  <div class="video-bar">
    <h2>{{ title }}</h2>
    <div class="video-bar__video" @click="requestFullScreen">
      <img src="/assets/play-button.svg" class="video-bar__icon" alt="" />
      <video ref="videoElement" muted autoplay loop :src="video"></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sample",
  props: {
    title: { type: String, required: true },
    video: { type: String, default: "" }
  },
  data() {
    return {
      playing: false,
      muted: true
    };
  },
  mounted() {
    document.addEventListener("fullscreenchange", () => {
      this.toggleMute();
    });
    document.addEventListener("mozfullscreenchange", () => {
      this.toggleMute();
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this.toggleMute();
    });
    document.addEventListener("msfullscreenchange", () => {
      this.toggleMute();
    });
  },
  methods: {
    toggleMute() {
      this.$refs.videoElement.muted = !this.muted;
      this.muted = !this.muted;
    },
    togglePlay() {
      this.playing = !this.playing;
      this.$refs.videoElement[this.playing ? "play" : "pause"]();
    },
    requestFullScreen() {
      if (this.$isMobile.any()) {
        this.togglePlay();
      }
      const elem = this.$refs.videoElement;
      elem.muted = false;
      elem.currentTime = 0;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  }
};
</script>
