export default function() {
  // ###### Scoped functions ######
  function isDescendant(parentClass, child) {
    var node = child.parentNode;
    while (node != null && node.classList) {
      if ([...node.classList].find(i => i === parentClass)) {
        return node;
      }
      node = node.parentNode;
    }
    return false;
  }

  const _q = selector => {
    return document.querySelector(selector);
  };
  const _qa = selector => {
    return document.querySelectorAll(selector);
  };
  // ###### END Menu behaviour ######

  // ###### Menu behaviour ######
  const header = _q(".main-header");
  window.addEventListener("scroll", function() {
    if (window.pageYOffset > 120) {
      header.classList.add("small");
    } else {
      header.classList.remove("small");
    }
  });
  const searchContainer = _q(".main-header__search-container");
  _q(".main-header__search-icon").addEventListener("click", function() {
    searchContainer.classList.toggle("active");
    if (searchContainer.classList.contains("active")) {
      searchContainer.querySelector('input[type="search"]').focus();
    }
  });

  // outside click event for menu
  window.addEventListener("click", function(e) {
    if (
      e.target.classList.contains("main-header__item--dropdown") ||
      isDescendant("main-header__item--dropdown", e.target)
    ) {
      return;
    }
    const el = _qa(".main-header__item:not(.dropdown-hidden)");
    [...el].forEach(e => {
      e.classList.add("dropdown-hidden");
    });
  });

  const dropdowns = _qa(".main-header__item--dropdown");
  for (let i = 0; i < dropdowns.length; i++) {
    dropdowns[i].addEventListener("click", function(ev) {
      [...dropdowns]
        .filter((_, key) => key !== i) // add hidden class except for current target
        .forEach(e => {
          e.classList.add("dropdown-hidden");
        });
      if (ev.target !== this) {
        return;
      } else {
        this.classList.toggle("dropdown-hidden");
      }
    });
  }
  // ###### END Menu behaviour ######

  // ###### Hero tab behaviour ######

  (function() {
    const tabContainer = _q("[data-hero-tabs]");
    const allTabContent = _qa("[data-hero-tab-content]");
    if (!tabContainer) {
      return;
    }
    [...tabContainer.children].forEach(el => {
      el.addEventListener("click", function(e) {
        const val = e.target.dataset.heroTabValue;
        [...allTabContent].forEach(t => {
          t.style.display = t.dataset.heroTabContent !== val ? "none" : "block";
        });
        [...tabContainer.children].forEach(t => {
          if (t.dataset.heroTabValue === val) {
            t.classList.add("active");
          } else {
            t.classList.remove("active");
          }
        });
      });
    });
  })();
  // ###### END Hero tab behaviour ######

  // ###### Parallax feature ######
  (function() {
    const elems = [];
    for (const el of [..._qa("[data-paralax]")]) {
      if (!el) {
        continue;
      }
      elems.push(el);
    }
    const animationTrigger = (animationRequest = true) => {
      for (const el of elems) {
        el.style.opacity = 1;
        const vpo = el.getBoundingClientRect();
        if (animationRequest) {
          window.requestAnimationFrame(() => {
            if (vpo.top * -1 < window.innerHeight) {
              el.style.transform = `translateY(${vpo.top / -5}px)`;
            }
          });
        } else {
          if (vpo.top * -1 < window.innerHeight) {
            el.style.transform = `translateY(${vpo.top / -5}px)`;
          }
        }
      }
    };
    setTimeout(() => {
      window.scrollTo(0, window.scrollY + 1);
      animationTrigger(false); // trigger to correct but without animationRequest
    }, 50);
    window.addEventListener("scroll", animationTrigger);
  })();
  // ###### END Parallax feature ######

  // ###### Thumbnail block - show more ######

  const showMore = _q("#projects_show_more");
  (() => {
    if (!showMore) {
      return;
    }
    showMore.addEventListener("click", function(e) {
      e.preventDefault();
      const parent = isDescendant("thumbnail-blocks", this);
      parent.classList.remove("cap");
      isDescendant("thumbnail-blocks__show-more", this).style.display = "none";
    });
  })();

  // ###### END Thumbnail block - show more ######

  // ###### Country tracker toggle ######

  const setCookie = () =>
    (document.cookie = `hideCountrypageWidget=${true};domain=${window.location.host};path=/`);

  const cookieElements = _qa("#country_tracker_close, #country_tracker_link");
  [...cookieElements].forEach(el => {
    el.addEventListener("click", function(e) {
      e.preventDefault();
      const parent = isDescendant("country-tracker", this);
      if (e.target.href) {
        setCookie();
        window.location.href = e.target.href;
        return;
      }
      if (parent) {
        parent.classList.add("closed");
        setCookie();
      }
    });
  });

  // ###### END Country tracker toggle ######
  (function() {
    const mobileMenu = _q("#mobile_menu");
    if (!mobileMenu) {
      return;
    }
    mobileMenu.addEventListener("click", () => {
      _q(".main-header").classList.toggle("active");
      searchContainer.classList.remove("active");
    });
  })();

  (function() {
    const list = document.querySelector("#project_specification_list");
    if (!list) {
      return;
    }
    const items = list.querySelectorAll("li");
    if (items.length > 3) {
      let toggle = false;
      const getListItemsHeight = (elements, amount = null) => {
        let itemsHeight = 145;
        [...elements].slice(0, amount || elements.length).forEach(li => {
          const styles = window.getComputedStyle(li);
          const margin = parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
          itemsHeight += li.offsetHeight + margin;
        });
        return itemsHeight + "px";
      };

      list.style.height = getListItemsHeight(items, 3);
      list.classList.add("type-collapse");
      document.querySelector("#toggle_specification_list").addEventListener("click", function(e) {
        e.preventDefault();
        toggle = !toggle;
        list.classList.toggle("collapsed");
        if (toggle) {
          list.style.height = getListItemsHeight(items);
        } else {
          list.style.height = getListItemsHeight(items, 3);
        }
      });
    }
  })();
}
