<template>
  <div class="image-gallery">
    <div class="grid-container image-gallery__wrapper">
      <div v-if="!renderMobile" class="image-gallery__big-img-container">
        <div
          v-if="preloaded"
          :style="{ backgroundImage: 'url(' + images[0].small + ')' }"
          class="image-gallery__img"
          @click="toggleModal(0)"
        ></div>
        <div v-else class="image-gallery__img"></div>
      </div>
      <div class="image-gallery__small-img-container">
        <template v-if="preloaded">
          <div
            v-for="(img, key) in smallImages"
            :key="key"
            :class="[
              'image-gallery__img',
              { stack: key == thumbnailOffset.key && stackedImageMode }
            ]"
            :style="{ backgroundImage: 'url(' + img.thumb + ')' }"
            @click="toggleModal(key + (renderMobile ? 0 : 1))"
          >
            <span v-if="key == thumbnailOffset.key && stackedImageMode"
              >+ {{ images.length - thumbnailOffset.offset + (renderMobile ? 1 : 0) }}</span
            >
          </div>
        </template>
        <template v-else>
          <div v-for="(img, key) in smallImages" :key="key" class="image-gallery__img"></div>
        </template>
      </div>
    </div>
    <div v-if="showModal" class="image-gallery__modal"></div>
  </div>
</template>

<script>
import { modal } from "../modal.js";
export default {
  name: "ImageGallery",
  props: {
    images: { type: Array, required: true },
    renderMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      preloaded: false,
      modal: null
    };
  },
  computed: {
    smallImages() {
      const clone = JSON.parse(JSON.stringify(this.images));
      !this.renderMobile && clone.shift();
      return clone.slice(0, this.thumbnailOffset.offset);
    },
    stackedImageMode() {
      return this.images.length > 7;
    },
    showModal() {
      return !isNaN(this.modal);
    },
    thumbnailOffset() {
      return this.renderMobile ? { key: 3, offset: 4 } : { key: 5, offset: 6 };
    }
  },
  async mounted() {
    try {
      const promises = this.images.map(i => i.original).map(this.preload);
      await Promise.all(promises);
      this.preloaded = true;
    } catch (err) {
      console.warn(err);
    }
  },
  methods: {
    async preload(img) {
      return new Promise(resolve => {
        const loaded = new Image();
        loaded.onload = function() {
          return resolve(this);
        };
        loaded.src = img;
      });
    },
    async toggleModal(ind) {
      await modal({
        component: "GalleryDetail",
        data: {
          current: ind,
          images: this.images.map(i => ({ url: i.original, caption: i.caption, source: i.source }))
        }
      });
    }
  }
};

// styling can be found in styles directory
</script>
