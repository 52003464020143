<template>
  <div v-if="currentImage" class="gallery-detail">
    <div class="gallery-detail__previous" @click="triggerImage(current - 1)">
      <svg class="gallery-detail__arrow">
        <use xlink:href="#arrow-left" fill="#000000"></use>
      </svg>
    </div>
    <img class="gallery-detail__img" alt="" :src="currentImage.url" />
    <div class="gallery-detail__next" @click="triggerImage(current + 1)">
      <svg class="gallery-detail__arrow">
        <use xlink:href="#arrow-right" fill="#000000"></use>
      </svg>
    </div>
    <div class="gallery-detail__info">
      <span class="gallery-detail__caption">{{ currentImage.caption }}</span>
      <span v-if="currentImage.source" class="gallery-detail__source"
        >Project by <strong>{{ currentImage.source }}</strong></span
      >
      <div class="gallery-detail__bullets">
        <div
          v-for="(img, key) in data.images"
          :key="key"
          :class="['gallery-detail__bullet', { active: key === current }]"
          @click="triggerImage(key)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryDetail",
  props: {
    data: { type: Object, required: true }
  },
  data() {
    return {
      current: null
    };
  },
  computed: {
    currentImage() {
      return !isNaN(this.current) ? this.data.images[this.current] : null;
    }
  },
  mounted() {
    this.current = this.data.current;
    const ctx = this;

    document.onkeydown = function(e) {
      e = e || window.event;
      if (e.keyCode == "37") {
        ctx.triggerImage(ctx.current - 1);
      } else if (e.keyCode == "39") {
        ctx.triggerImage(ctx.current + 1);
      }
    };
  },
  methods: {
    triggerImage(newIndex) {
      if (newIndex > this.data.images.length - 1) {
        this.current = 0;
      } else if (newIndex < 0) {
        this.current = this.data.images.length - 1;
      } else {
        this.current = newIndex;
      }
    }
  }
};
</script>

<style></style>
