<template>
  <div v-if="showWidget" class="news-filter">
    <div class="news-filter__categories">
      <div
        v-for="cat in categories"
        :key="cat.id"
        :class="['news-filter__categories-single', { active: selectedCategories.includes(cat.id) }]"
        @click="toggleCategory(cat.id)"
      >
        <span>{{ cat.title }}</span>
      </div>
    </div>
    <div class="news-filter__articles">
      <a v-for="article in results" :key="article.id" :href="article.url" class="article-single">
        <div class="article-single__category-label">
          <span v-for="cat in article.categories" :key="cat.id">{{ cat.title }}</span>
        </div>
        <div
          class="article-single__img"
          :style="{ 'background-image': 'url(' + article.image + ')' }"
          :alt="article.title"
        />
        <div class="article-single__meta">
          <h3 class="article-single__title">{{ article.title }}</h3>
          <span class="article-single__date">{{ article.published }}</span>
        </div>
      </a>
      <strong v-if="!results.length" class="article-single__no-results"
        >Er zijn geen artikelen met deze criteria</strong
      >
    </div>
    <div class="news-filter__loadmore-container">
      <a
        v-if="results.length < meta.pagination.total"
        href="#"
        class="news-filter__loadmore"
        @click.prevent="loadMore"
        >Load more
        <Spinner v-if="fetching" class="news-filter__loadmore-spinner" />
      </a>
    </div>
  </div>
  <div v-else class="news-filter__load-widget">
    <Spinner class="news-filter__load-widget-spinner" />
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";

export default {
  name: "NewsFilter",
  components: { Spinner },
  data() {
    return {
      categories: [],
      selectedCategories: [],
      results: [],
      page: 1,
      meta: {},
      fetching: false,
      showWidget: false
    };
  },
  async mounted() {
    try {
      const { data } = await this.$fetch("categories");
      this.categories = data;
      await this.toggleSearch();
      this.showWidget = true;
    } catch (err) {
      this.showWidget = true;
      return Promise.reject(err);
    }
  },

  methods: {
    toggleCategory(id) {
      if (this.selectedCategories.includes(id)) {
        this.selectedCategories.splice(this.selectedCategories.indexOf(id), 1);
      } else {
        this.selectedCategories.push(id);
      }
      this.toggleSearch();
    },
    async toggleSearch(nextPage = false) {
      nextPage ? this.page++ : (this.page = 1);
      this.fetching = true;
      try {
        const cats = this.selectedCategories.length
          ? this.selectedCategories
          : this.categories.map(c => c.id);
        const { data, meta } = await this.$fetch(
          `articles?page=${this.page}&${cats.map(q => `categories[]=${q}`).join("&")}`
        );
        this.results = nextPage ? [...this.results, ...data] : data;
        this.meta = meta;
        this.fetching = false;
        return Promise.resolve(this.results);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async loadMore() {
      this.toggleSearch(true);
    }
  }
};
// styling can be found in styles directory
</script>
