import Vue from "vue";
import "./components";
import "./styles/main.scss";
import events from "./events.js";
import isMobile from "./ismobile.js";
import RequestClient from "./requestClient.js";

Vue.use(isMobile);
Vue.use(RequestClient);
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});

events();
