import axios from "axios";
export default function(Vue) {
  const client = axios.create({
    baseURL: "/api/",
    timeout: 5000, // 5 sec
    withCredentials: true
  });
  client.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      if (error.response) {
        return Promise.reject(error);
      }
    }
  );
  Vue.prototype.$fetch = client;
}
